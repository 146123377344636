import React, { useState } from 'react';
import { IoIosSearch, IoIosClose } from 'react-icons/io';
import { DrawerProvider } from '../drawer/drawer-context';
import Menu from './menu';
import MobileMenu from './mobile-menu';
import SearchContainer from '../../containers/search/search';
import { Link } from 'gatsby';
import HeaderWrapper, {
  NavbarWrapper,
  Logo,
  MenuWrapper,
  NavSearchButton,
  NavSearchWrapper,
  SearchCloseButton,
  NavSearchFromWrapper,
} from './navbar.style';
import LogoImage from '../../images/logo.png';
import It from '../../images/it_it.gif'
import Fr from '../../images/fr_fr.gif'
import En from '../../images/en.gif'

type NavbarProps = {
  className?: string;
};

const MenuItems = [
  {
    label: 'Artisti',
    url: '/artistesIT',
  },
  {
    label: 'Mostre in corso',
    url: '/expositions-en-coursIT',
  },
  {
    label: 'chi siamo',
    url: '/a-proposIT',
  },
  {
    label: 'Contatto',
    url: '/contactIT',
  },
  {
    label: 'video',
    url: '/videoIT',
  },
  
];

const NavbarIT: React.FunctionComponent<NavbarProps> = ({
  className,
  ...props
}) => {
  const [state, setState] = useState({
    toggle: false,
    search: '',
  });
  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    });
  };

  // Add all classs to an array
  const addAllClasses = ['header'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <HeaderWrapper className={addAllClasses.join(' ')} {...props}>
      <NavbarWrapper className="navbar">
        <DrawerProvider>
          <MobileMenu items={MenuItems} logo={LogoImage} />
        </DrawerProvider>
        <Logo>
          <Link to="/it">
            <img src={LogoImage} alt="logo" />
          </Link>
        </Logo>
        <MenuWrapper>
          <Menu items ={MenuItems} />
        <Logo>
          <Link to="/it">
            <img src={It} alt="Italiano" />
          </Link>
        </Logo>
        <Logo>
          <Link to="/en">
            <img src={En} alt="English" />
          </Link>
        </Logo>
        <Logo>
        <Link to="/">
           <img src={Fr} alt="France" />
          </Link>
        </Logo>   
        </MenuWrapper>
        {/* <NavSearchButton
          type="button"
          aria-label="search"
          onClick={toggleHandle}
        >
          <IoIosSearch size="23px" />
        </NavSearchButton> */}
      </NavbarWrapper>

      {/* <NavSearchWrapper className={state.toggle === true ? 'expand' : ''}>
        <NavSearchFromWrapper>
          <SearchContainer />
          <SearchCloseButton
            type="submit"
            aria-label="close"
            onClick={toggleHandle}
          >
            <IoIosClose />
          </SearchCloseButton>
        </NavSearchFromWrapper>
      </NavSearchWrapper> */}
    </HeaderWrapper>
  );
};

export default NavbarIT;
